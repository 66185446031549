import getOr from 'lodash/fp/getOr';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';

import * as Actions from '../../actions';
import * as selectors from '../../adminRolesSelectors';
import Expandable, { Trigger } from '../util/Expandable';
import { Cell } from '../util/Grid';
import Hoverable from '../util/Hoverable';

import { Dispatch } from '../../actions';
import type { RightsCategory } from '@socrata/core-roles-api';
import { ForgeIcon } from '@tylertech/forge-react';

// We used to pull this from ../variables but it no longer works.
const cellHeight = 60;

interface OwnProps {
  rightCategory: RightsCategory;
  editMode: boolean;
}

interface DispatchProps {
  toggleExpanded: () => Actions.ToggleExpandedAction;
}

const mapDispatchToProps = (dispatch: Dispatch, { rightCategory }: OwnProps): DispatchProps =>
  bindActionCreators(
    {
      toggleExpanded: () => Actions.toggleExpanded(rightCategory)
    },
    dispatch
  );

type Props = OwnProps & DispatchProps;

class RightCategory extends Component<Props> {
  render() {
    const { rightCategory, toggleExpanded, editMode } = this.props;
    return (
      <Expandable
        className="expandable-container"
        itemHeight={cellHeight}
        isExpanded={selectors.getExpandedStateFromRightsCategory(rightCategory)}
      >
        <Hoverable name={getOr('', 'translationKey', rightCategory)}>
          <Trigger className="right-cell-trigger" onClick={toggleExpanded}>
            <h6>{I18n.t(selectors.getTranslationKeyPathFromRightsCategory(rightCategory))}</h6>
          </Trigger>
        </Hoverable>
        {this.inlineMessage(rightCategory.translationKey)}
        {selectors.getRightsFromRightsCategory(rightCategory).map((right) => (
          <Hoverable name={getOr('', 'name', right)} key={selectors.getNameFromRight(right)}>
            <Cell className="right-cell-item">
              <span
                dangerouslySetInnerHTML={{
                  __html: I18n.t(selectors.getNameTranslationKeyPathFromRight(right))
                }}
              />
            </Cell>
          </Hoverable>
        ))}
      </Expandable>
    );
  }

  inlineMessage = (rightCategoryName: string) => {
    if (rightCategoryName === 'review_and_approve_assets' && this.props.editMode) {
      return (
        <div className="inline-message">
          <ForgeIcon className="info-icon" slot="icon" name="info" />
          <span
            dangerouslySetInnerHTML={{
              __html: I18n.t('roles.rights.inline_message')
            }}
          />
        </div>
      );
    }
  };
}

export default connect(null, mapDispatchToProps)(RightCategory);
