import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button, { VARIANTS, SIZES } from 'common/components/Button';
import { IconName, SocrataIcon } from 'common/components/SocrataIcon';

import * as Actions from '../../actions';
import * as Selectors from '../../adminRolesSelectors';

import { Dispatch } from '../../actions';
import type { Role } from '@socrata/core-roles-api';

import './role-edit-control.scss';

interface OwnProps {
  role: Role;
}

interface DispatchProps {
  deleteRole: () => Actions.DeleteRoleAction;
  editRole: () => Actions.EditRoleStartAction;
  renameRole: () => Actions.RenameRoleAction;
}

const mapDispatchToProps = (dispatch: Dispatch, { role }: OwnProps): DispatchProps =>
  bindActionCreators(
    {
      deleteRole: () => Actions.deleteRole(role),
      editRole: () => Actions.editRoleStart(role),
      renameRole: () => Actions.renameRole(role)
    },
    dispatch
  );

interface ItemProps {
  hideDropdown: () => void;
  name: string;
  onClick: () => void;
}

class DropdownItem extends Component<ItemProps> {
  render() {
    const { hideDropdown, name, onClick } = this.props;
    const handleClick = () => {
      hideDropdown();
      onClick();
    };

    return (
      <li>
        <Button transparent onClick={handleClick}>
          {name}
        </Button>
      </li>
    );
  }
}

type ControlProps = DispatchProps & OwnProps;

interface ControlState {
  showDropdown: boolean;
}

class RoleEditControl extends Component<ControlProps, ControlState> {
  state = {
    showDropdown: false
  };

  toggleDocumentMouseDown = (isMounted: boolean) => {
    window.document[isMounted ? 'addEventListener' : 'removeEventListener']('mousedown', this.onMouseDown);
  };

  onMouseDown = (ev: Event) => {
    const target = ev.target as HTMLElement;
    if (!this.dropdownRef || !(this.dropdownRef === target || this.dropdownRef.contains(target))) {
      this.setState({ showDropdown: false });
    }
  };

  componentDidMount() {
    this.toggleDocumentMouseDown(true);
  }

  componentWillUnmount() {
    this.toggleDocumentMouseDown(false);
  }

  hideDropdown = () => {
    this.setState({ showDropdown: false });
  };

  toggleDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  };

  handleDeleteRole = () => {
    const { deleteRole, role } = this.props;
    const confirmationMessage = I18n.t('screens.admin.roles.confirmation.delete_role', {
      name: Selectors.getRoleNameFromRole(role)
    });
    if (window.confirm(confirmationMessage)) {
      deleteRole();
    }
  };

  dropdownRef: HTMLElement | null;

  render() {
    const { editRole, renameRole, role } = this.props;
    const roleIsNew = Selectors.roleIsNew(role);
    const { showDropdown } = this.state;

    return (
      <div className="admin-roles-edit-container" ref={(ref) => (this.dropdownRef = ref)}>
        <Button
          variant={VARIANTS.SIMPLE}
          size={SIZES.X_SMALL}
          className="role-edit-control"
          onClick={this.toggleDropdown}
        >
          <SocrataIcon name={IconName.Kebab} />
        </Button>
        {showDropdown && (
          <ul>
            <DropdownItem
              name={I18n.t('screens.admin.roles.index_page.edit_controls.rename_role')}
              hideDropdown={this.hideDropdown}
              onClick={renameRole}
            />
            {!roleIsNew && (
              <DropdownItem
                name={I18n.t('screens.admin.roles.index_page.edit_controls.edit_role')}
                hideDropdown={this.hideDropdown}
                onClick={editRole}
              />
            )}
            {!roleIsNew && (
              <DropdownItem
                name={I18n.t('screens.admin.roles.index_page.edit_controls.delete_role')}
                hideDropdown={this.hideDropdown}
                onClick={this.handleDeleteRole}
              />
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(RoleEditControl);
