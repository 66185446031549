import cx from 'classnames';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from '../../actions';

import { Dispatch } from '../../actions';
import { State } from 'adminRoles/types';

const mapStateToProps = (state: State): StateProps => ({
  hovered: get('hovered', state)
});

const mapDispatchToProps = (dispatch: Dispatch, { name }: { name: string }): DispatchProps =>
  bindActionCreators(
    {
      hoverRow: () => Actions.hoverRow(name),
      unhoverRow: () => Actions.unhoverRow(name)
    },
    dispatch
  );

interface OwnProps {
  children: React.ReactElement;
  name: string;
}

interface StateProps {
  hovered?: string;
}

interface DispatchProps {
  hoverRow: (evt: React.MouseEvent<HTMLElement>) => Actions.HoverRowAction;
  unhoverRow: (evt: React.MouseEvent<HTMLElement>) => Actions.UnhoverRowAction;
}
type Props = OwnProps & StateProps & DispatchProps;

class Hoverable extends Component<Props> {
  render() {
    const { children, name, hoverRow, unhoverRow, hovered, ...props } = this.props;

    const className = cx({ hovered: name === hovered });

    const childrenWithExtraProp = React.Children.map(children, (child: React.ReactElement) =>
      React.cloneElement(child, omit(['styles'], props))
    );

    return (
      <div className={className} onMouseEnter={hoverRow} onMouseLeave={unhoverRow}>
        {childrenWithExtraProp}
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(Hoverable);
