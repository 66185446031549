import React, { Component } from 'react';
import { connect } from 'react-redux';

import ScrollElement from './ScrollElement';
import ScrollView from './ScrollView';
import * as Selectors from '../../../adminRolesSelectors';
import { State } from 'adminRoles/types';

import './scroll-container.scss';

interface StateProps {
  scrollTo: string | number | null;
}

const mapStateToProps = (state: State): StateProps => ({
  scrollTo: Selectors.scrollToNewRole(state)
});

interface OwnProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}

type Props = StateProps & OwnProps;

export class ScrollContainer extends Component<Props> {
  _scrollView: ScrollView | null;

  componentDidUpdate(prevProps: Props) {
    if (this._scrollView && prevProps.scrollTo !== this.props.scrollTo) {
      this._scrollView.scrollTo(this.props.scrollTo);
    }
  }

  render() {
    const { children, className } = this.props;

    return (
      <ScrollView ref={(ref) => (this._scrollView = ref)}>
        <div className={`admin-roles-scroll-container ${className}`} data-scroll-container={true}>
          {React.Children.map(children, (child: React.ReactElement) => (
            <ScrollElement name={child.props.name}>{child}</ScrollElement>
          ))}
        </div>
      </ScrollView>
    );
  }
}

export default connect(mapStateToProps)(ScrollContainer);
