import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../adminRolesSelectors';
import Grid from '../util/Grid';
import ScrollContainer from '../util/ScrollContainer';
import RightsColumn from './RightsColumn';
import RoleColumn from './RoleColumn';

import type { State } from '../../types';
import type { Role } from '@socrata/core-roles-api';

import './roles-grid.scss';
import { DomainRoleMask } from '@socrata/core-domain-roles-mask-api';

interface StateProps {
  roles: Role[];
  domainRoleMask: DomainRoleMask[];
}

const mapStateToProps = (state: State): StateProps => ({
  roles: selectors.getRolesFromState(state),
  domainRoleMask: selectors.getDomainRoleMaskFromState(state)
});

type Props = StateProps;

class RolesGrid extends Component<Props> {
  render() {
    const { roles, domainRoleMask } = this.props;
    const roleColumns = roles.map((role: Role) => (
      <RoleColumn
        key={`${selectors.getIdFromRole(role)}_${selectors.getRoleNameFromRole(role)}`}
        role={role}
        domainRoleMask={domainRoleMask}
      />
    ));
    return (
      <div className="admin-roles-grid">
        <Grid className="roles-grid">
          <RightsColumn />
          <ScrollContainer className="scroll-container">{roleColumns}</ScrollContainer>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps)(RolesGrid);
