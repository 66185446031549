export type AppState =
  | 'DEFAULT'
  | 'NEW_CUSTOM_ROLE'
  | 'EDIT_CUSTOM_ROLES'
  | 'EDIT_INDIVIDUAL_CUSTOM_ROLE'
  | 'RENAME_INDIVIDUAL_CUSTOM_ROLE'
  | 'DELETE_INDIVIDUAL_CUSTOM_ROLE'
  | 'SAVING'
  | 'LOADING'
  | 'LOAD_DATA_FAILURE';
export const DEFAULT = 'DEFAULT';
export const NEW_CUSTOM_ROLE = 'NEW_CUSTOM_ROLE';
export const EDIT_CUSTOM_ROLES = 'EDIT_CUSTOM_ROLES';
export const EDIT_INDIVIDUAL_CUSTOM_ROLE = 'EDIT_INDIVIDUAL_CUSTOM_ROLE';
export const RENAME_INDIVIDUAL_CUSTOM_ROLE = 'RENAME_INDIVIDUAL_CUSTOM_ROLE';
export const DELETE_INDIVIDUAL_CUSTOM_ROLE = 'DELETE_INDIVIDUAL_CUSTOM_ROLE';
export const SAVING = 'SAVING';
export const LOADING = 'LOADING';
export const LOAD_DATA_FAILURE = 'LOAD_DATA_FAILURE';
