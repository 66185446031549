import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import curry from 'lodash/fp/curry';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import has from 'lodash/fp/has';
import negate from 'lodash/fp/negate';
import stubFalse from 'lodash/fp/stubFalse';
import stubTrue from 'lodash/fp/stubTrue';
import union from 'lodash/fp/union';
import without from 'lodash/fp/without';

import * as appStates from './appStates';

import { CheckedState } from './components/util/TristateCheckbox';
import type { RoleError, State, UIRole, ExpandableRightsCategory } from 'adminRoles/types';
import type { ServerConfig } from 'common/types/windowSocrata';
import type { Role, SortedRight, RightsCategory } from '@socrata/core-roles-api';
import { DomainRoleMask } from '@socrata/core-domain-roles-mask-api';

export const getSortOrderFromRole = (role: Role): number =>
  getOr(Number.MAX_SAFE_INTEGER, 'defaultSortOrder', role);
export const roleIsDefault = (role: Role): boolean => getOr(false, 'isDefault', role);
export const roleIsCustom = negate(roleIsDefault);
export const getRightCategoriesFromState = (state: State) => getOr([], 'rightCategories', state);
export const getCreatedAtDateFromRole = (role: Role): number =>
  getOr(Number.MAX_SAFE_INTEGER, 'createdAt', role);
const sortRoles = (r1: Role, r2: Role) => {
  const r1Default = roleIsDefault(r1);
  const r2Default = roleIsDefault(r2);

  if (r1Default) {
    if (r2Default) {
      return getSortOrderFromRole(r1) - getSortOrderFromRole(r2);
    } else {
      return -1;
    }
  } else {
    if (r2Default) {
      return 1;
    } else {
      return getCreatedAtDateFromRole(r1) - getCreatedAtDateFromRole(r2);
    }
  }
};
export const getRolesFromState = (state: State | any): Role[] => {
  const roles: Role[] = getOr([], 'roles', state);
  return roles.sort(sortRoles);
};

export const getDomainRoleMaskFromState = (state: State | any): DomainRoleMask[] => {
  const domainRoleMask: DomainRoleMask[] = getOr([], 'domainRoleMask', state);
  return domainRoleMask;
};

export const getRoleNameFromRole = (role: Role): string => getOr('', 'name', role);
export const getRoleNameTranslationKeyPathFromRole = (role: Role) =>
  `roles.default_roles.${getRoleNameFromRole(role)}.name`;
export const getIdFromRole = (role: Role): number => getOr(-1, 'id', role);
export const getRightsFromRole = (role: Role): string[] => getOr([], 'rights', role);
export const getNumberOfUsersFromRole = (role: Role) => getOr(0, 'numberOfUsers', role);
export const getNumberOfInvitedUsersFromRole = (role: Role) => getOr(0, 'numberOfFutureAccounts', role);

export const getSortOrderFromRight = (right: SortedRight) =>
  getOr(Number.MAX_SAFE_INTEGER, 'sortOrder', right);
const sortRights = (r1: SortedRight, r2: SortedRight) =>
  getSortOrderFromRight(r1) - getSortOrderFromRight(r2);
export const getRightsFromRightsCategory = (category: RightsCategory) =>
  getOr([], 'rights', category).sort(sortRights);
export const getExpandedStateFromRightsCategory = (category: RightsCategory) =>
  getOr(false, 'isExpanded', category);
export const getTranslationKeyFromRightsCategory = (category: RightsCategory) =>
  get('translationKey', category);
export const getTranslationKeyPathFromRightsCategory = (category: RightsCategory) =>
  `roles.right_categories.${getTranslationKeyFromRightsCategory(category)}.name`;

export const getNameFromRight = (right: SortedRight): string => get('name', right);
export const getNameTranslationKeyPathFromRight = (right: SortedRight) =>
  `roles.rights.${getNameFromRight(right)}.name`;
export const getRightNamesFromRightsCategory = (category: RightsCategory) =>
  getRightsFromRightsCategory(category).map(getNameFromRight);

export const removeRightFromRole = (role: Role, right: SortedRight): Role => ({
  ...role,
  rights: (role.rights || []).filter((r) => r !== getNameFromRight(right))
});

export const addRightToRole = (role: Role, right: SortedRight): Role => ({
  ...role,
  rights: (role.rights || []).concat([getNameFromRight(right)])
});

export const addAllRightsInCategoryToRole = (role: Role, category: RightsCategory): Role => ({
  ...role,
  rights: union(getRightNamesFromRightsCategory(category), role.rights)
});

export const removeAllRightsInCategoryFromRole = (role: Role, category: RightsCategory): Role => ({
  ...role,
  rights: without(getRightNamesFromRightsCategory(category), role.rights)
});

export const findRoleById = (state: State, roleId: number | null): Role | void =>
  find((r) => getIdFromRole(r) === roleId, getRolesFromState(state));

export const roleIsNew = (role: Role) => !has('createdAt', role);
export const roleHasRightByName = curry((role: Role, rightName: string) =>
  getRightsFromRole(role).includes(rightName)
);
export const roleHasRight = curry((role: Role, right: SortedRight) =>
  roleHasRightByName(role, getNameFromRight(right))
);
export const roleHasAnyRightInRightsCategory = curry((role: Role, category: RightsCategory) => {
  const roleRights = getRightsFromRole(role);
  return getRightsFromRightsCategory(category).some((right: SortedRight) =>
    roleRights.includes(getNameFromRight(right))
  );
});
export const roleHasAllRightsInRightsCategory = curry((role: Role, category: RightsCategory) => {
  const roleRights = getRightsFromRole(role);
  return getRightsFromRightsCategory(category).every((right: SortedRight) =>
    roleRights.includes(getNameFromRight(right))
  );
});

export const rightCategoryStateForRole = curry((role: Role, category: RightsCategory) =>
  cond<RightsCategory, CheckedState>([
    [roleHasAllRightsInRightsCategory(role), stubTrue],
    [roleHasAnyRightInRightsCategory(role), constant('partial')],
    [stubTrue, stubFalse]
  ])(category)
);

// TODO: This State | object is a workaround for the fact that `previousState` could be missing... Introduce an EmptyState interface?
export const getPreviousState = (state: State): State | any => getOr({}, 'previousState', state);

export const getDirtyRolesFromState = (state: State): Role[] =>
  without<Role>(getRolesFromState(getPreviousState(state)), getRolesFromState(state));

export const getEditingRoleFromState = (state: State): Role =>
  getOr(
    {
      id: -1,
      name: '',
      isDefault: false,
      rights: [],
      numberOfUsers: -1
    },
    'editingRole',
    state
  );
export const getEditingRoleTemplateIdFromState = (state: State) =>
  getOr(null, 'template', getEditingRoleFromState(state));

export const updateRightsCategoryInState = (
  state: State,
  category: RightsCategory,
  updateFn: (rc: RightsCategory) => RightsCategory
): State => ({
  ...state,
  rightCategories: state.rightCategories.map((item: RightsCategory) =>
    item === category ? updateFn(item) : item
  )
});

export const toggleRightsCategoryExpanded = (
  category: ExpandableRightsCategory
): ExpandableRightsCategory => ({
  ...category,
  isExpanded: !category.isExpanded
});

export const getAppState = (state: State) => getOr(appStates.DEFAULT, 'appState', state);
export const getNotificationFromState = (state: State) => getOr({}, 'notification', state);

export const getMaxCharacterCountFromState = (state: State) =>
  getOr(Number.MAX_SAFE_INTEGER, 'maxCharacterCount', state);
export const getFaqUrlFromState = (state: State) => getOr('', 'rolesAdminFaqUrl', state);

export const isEditCustomRolesAppState = (state: State) => appStates.EDIT_CUSTOM_ROLES === getAppState(state);
export const isEditIndividualRoleAppState = (state: State) =>
  appStates.EDIT_INDIVIDUAL_CUSTOM_ROLE === getAppState(state);
export const stateHasCustomRoles = (state: State) => getRolesFromState(state).some(roleIsCustom);

export const configurableRoleFeatureFlagFromState = (state: State) =>
  getOr(false, 'featureFlags.configurable_roles', state);

export const updateRoleWithError = (error: RoleError, role: Role): UIRole => ({
  ...role,
  error,
  hasError: true
});

export const validateRole: (t1: number, t2: Role) => Role = curry((maxCharacterCount: number, role: Role) => {
  const name = getRoleNameFromRole(role);
  if (name.length === 0) {
    return updateRoleWithError(
      {
        message: 'screens.admin.roles.errors.role_name_empty'
      },
      role
    );
  }

  if (name.length > maxCharacterCount) {
    return updateRoleWithError(
      {
        message: 'screens.admin.roles.errors.role_name_length',
        maxCharacterCount
      },
      role
    );
  }
  return role;
});

export const roleHasError = (role: Role) => getOr(false, 'hasError', role);

export const scrollToNewRole = (state: State) => getOr(null, 'scrollToNewRole', state);

export const clearNewRole = (state: State): State => ({
  ...state,
  editingRole: {
    id: -1,
    isDefault: false,
    rights: [],
    name: '',
    hasError: false,
    numberOfUsers: 0
  }
});

export const getInitialState = (serverConfig: ServerConfig): State => ({
  appState: appStates.LOADING,
  maxCharacterCount: 35,
  rightCategories: [],
  roles: [],
  domainRoleMask: [],
  ...serverConfig
});
