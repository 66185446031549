import includes from 'lodash/fp/includes';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import { currentUserHasRight } from 'common/current_user';
import DomainRights from 'common/types/domainRights';

import * as Actions from '../actions';
import * as Selectors from '../adminRolesSelectors';
import { DEFAULT, EDIT_CUSTOM_ROLES } from '../appStates';

import { State } from 'adminRoles/types';

import './edit-bar.scss';

interface StateProps {
  faqUrl: string;
  hasConfigurableRoleFeature: boolean;
  isAddCustomRoleEnabled: boolean;
  isEditCustomRolesEnabled: boolean;
}

const mapStateToProps = (state: State): StateProps => {
  const appState = Selectors.getAppState(state);
  const hasCustomRoles = Selectors.stateHasCustomRoles(state);
  const enableConfigurableRoles = Selectors.configurableRoleFeatureFlagFromState(state);
  const hasConfigurableRoleFeature =
    currentUserHasRight(DomainRights.manage_users) || enableConfigurableRoles;

  return {
    hasConfigurableRoleFeature,
    isEditCustomRolesEnabled: hasCustomRoles && includes(appState, [DEFAULT]),
    isAddCustomRoleEnabled: includes(appState, [DEFAULT, EDIT_CUSTOM_ROLES]),
    faqUrl: Selectors.getFaqUrlFromState(state)
  };
};

interface DispatchProps {
  newCustomRole: () => Actions.NewCustomRoleAction;
  startEditCustomRoles: () => Actions.EditCustomRolesStartAction;
}

const mapDispatchToProps: DispatchProps = {
  newCustomRole: Actions.newCustomRole,
  startEditCustomRoles: Actions.editCustomRolesStart
};

type Props = StateProps & DispatchProps;

class EditBar extends Component<Props> {
  render() {
    const {
      isAddCustomRoleEnabled,
      isEditCustomRolesEnabled,
      hasConfigurableRoleFeature,
      startEditCustomRoles,
      newCustomRole,
      faqUrl
    } = this.props;

    return (
      <div className="admin-roles-edit-bar">
        {faqUrl ? (
          <a href={faqUrl} target="_blank" className="admin-roles-faq-button">
            <i className="socrata-icon-question" />
            {I18n.t('screens.admin.roles.index_page.faq_modal.title')}
          </a>
        ) : null}
        {hasConfigurableRoleFeature ? (
          <Button
            variant={VARIANTS.DEFAULT}
            buttonDisabledStyle="light"
            disabled={!isEditCustomRolesEnabled}
            onClick={startEditCustomRoles}
          >
            <i className="socrata-icon-edit" />
            {I18n.t('screens.admin.roles.buttons.edit_custom_roles')}
          </Button>
        ) : null}
        {hasConfigurableRoleFeature ? (
          <Button variant={VARIANTS.PRIMARY} disabled={!isAddCustomRoleEnabled} onClick={newCustomRole}>
            <i className="socrata-icon-plus3" />
            {I18n.t('screens.admin.roles.buttons.new_custom_role')}
          </Button>
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBar);
