import cx from 'classnames';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';

import * as Actions from '../../actions';
import * as Selectors from '../../adminRolesSelectors';
import { NEW_CUSTOM_ROLE } from '../../appStates';
import BoundedTextInput from '../util/BoundedTextInput';
import TemplateDropdown from './TemplateDropdown';
import type { State } from 'adminRoles/types';
import type { Role } from '@socrata/core-roles-api';

import './custom-role-form.scss';

interface StateProps extends Role {
  editingNewRole: boolean;
  maxCharacterCount: number;
}

const mapStateToProps = (state: State): StateProps => {
  const appState = Selectors.getAppState(state);

  const roleToEdit = Selectors.getEditingRoleFromState(state);

  return {
    ...roleToEdit,
    editingNewRole: appState === NEW_CUSTOM_ROLE,
    maxCharacterCount: Selectors.getMaxCharacterCountFromState(state)
  };
};

interface DispatchProps {
  onNameChange: (name: string) => Actions.ChangeNewRoleNameAction;
  onTemplateChange: (value: string) => Actions.ChangeNewRoleTemplateAction;
}

const mapDispatchToProps: DispatchProps = {
  onNameChange: Actions.changeNewRoleName,
  onTemplateChange: Actions.changeNewRoleTemplate
};

interface OwnProps {
  onSubmit: (x?: any) => any;
  error?: { message: string };
  hasError?: boolean;
  name?: string | null;
  template?: string | number;
}

type Props = StateProps & DispatchProps & OwnProps;

class CustomRoleForm extends Component<Props> {
  static defaultProps = {
    hasError: false
  };
  nameInput: HTMLElement | null;

  focusInput = () => {
    this.nameInput && this.nameInput.focus();
  };

  componentDidMount() {
    this.focusInput();
  }

  componentDidUpdate() {
    if (this.props.hasError) {
      this.focusInput();
    }
  }

  render() {
    const {
      editingNewRole,
      error,
      hasError,
      maxCharacterCount,
      name,
      onSubmit,
      onNameChange,
      onTemplateChange,
      template
    } = this.props;

    const textInputClasses = cx(
      {
        'text-input-error': hasError
      },
      'text-input'
    );

    return (
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit();
        }}
      >
        <label className="block-label" htmlFor="role-name">
          {I18n.t('screens.admin.roles.index_page.custom_role_modal.form.role_name.label')}
        </label>
        <BoundedTextInput
          maxCharacterCount={maxCharacterCount}
          inputRef={(input: HTMLElement | null) => {
            this.nameInput = input;
          }}
          className={`admin-roles-modal-role-name ${textInputClasses}`}
          id="role-name"
          type="text"
          placeholder={I18n.t('screens.admin.roles.index_page.custom_role_modal.form.role_name.placeholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onNameChange(event.target.value)}
          value={name || undefined}
        />
        {hasError && (
          <div className="alert error">{I18n.t(get('message', error), omit(['message'], error))}</div>
        )}
        {editingNewRole && (
          <label className="block-label" htmlFor="template-name">
            {I18n.t('screens.admin.roles.index_page.custom_role_modal.form.template.label')}
          </label>
        )}
        {editingNewRole && (
          <TemplateDropdown onChange={(value: string) => onTemplateChange(value)} value={template} />
        )}
      </form>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(CustomRoleForm);
