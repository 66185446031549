import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import scrollIntoView from 'scroll-into-view';

interface Props {
  children: React.ReactElement;
}

/** ScrollView / ScrollElement
 * Inspired by https://codedaily.io/tutorials/8/Build-a-Reusable-Scroll-List-Component-with-Animated-scrollTo-in-React
 *
 */
export class ScrollView extends Component<Props> {
  static childContextTypes = {
    scroll: PropTypes.object
  };
  elements = {};

  scrollTo = (name: string | number | null) => {
    if (!name) {
      return;
    }
    const node = findDOMNode(this.elements[name]);
    scrollIntoView(node as unknown as HTMLElement, {
      time: 500,
      align: {
        left: 1
      },
      validTarget: function (target: HTMLElement) {
        return target.getAttribute && target.getAttribute('data-scroll-container') === 'true';
      }
    });
  };

  register = (name: string, ref: HTMLElement) => {
    this.elements[name] = ref;
  };

  unregister = (name: string) => {
    delete this.elements[name];
  };

  getChildContext = () => {
    return {
      scroll: {
        register: this.register,
        unregister: this.unregister
      }
    };
  };

  render() {
    return React.Children.only(this.props.children);
  }
}

export default ScrollView;
