import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';
import React, { Component } from 'react';
import { spring } from 'react-motion';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import ConditionTransitionMotion from 'common/components/ConditionTransitionMotion';
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';

import * as Actions from '../../actions';
import * as Selectors from '../../adminRolesSelectors';
import { NEW_CUSTOM_ROLE, RENAME_INDIVIDUAL_CUSTOM_ROLE } from '../../appStates';
import CustomRoleForm from './CustomRoleForm';

import { State } from 'adminRoles/types';

import './edit-custom-role-modal.scss';

interface StateProps {
  showModal: boolean;
  editingNewRole: boolean;
}

const mapStateToProps = (state: State): StateProps => {
  const appState = Selectors.getAppState(state);
  const showModal = includes(appState, [NEW_CUSTOM_ROLE, RENAME_INDIVIDUAL_CUSTOM_ROLE]);

  return {
    showModal,
    editingNewRole: appState === NEW_CUSTOM_ROLE
  };
};

interface DispatchProps {
  onCancel: (evt: React.SyntheticEvent<HTMLButtonElement>) => Actions.EditCustomRoleModalCancelAction;
  onSubmit: (evt: React.SyntheticEvent<HTMLButtonElement>) => Actions.EditCustomRoleModalSubmitAction;
}

const mapDispatchToProps: DispatchProps = {
  onCancel: Actions.editCustomRoleModalCancel,
  onSubmit: Actions.editCustomRoleModalSubmit
};

type Props = StateProps & DispatchProps;

class EditCustomRoleModal extends Component<Props> {
  render() {
    const { onCancel, onSubmit, editingNewRole, showModal } = this.props;
    const modalProps = {
      fullScreen: false,
      onDismiss: noop // don't allow clicking outside to hide
    };
    const headerProps = {
      showCloseButton: false,
      title: I18n.t(
        editingNewRole
          ? 'screens.admin.roles.index_page.custom_role_modal.title'
          : 'screens.admin.roles.index_page.custom_role_modal.rename_title'
      ),
      onDismiss: noop
    };

    return (
      <ConditionTransitionMotion
        condition={showModal}
        willEnter={() => ({ y: -100, opacity: 0 })}
        willLeave={() => ({ y: spring(-100), opacity: spring(0) })}
        style={{ y: spring(0), opacity: spring(1) }}
      >
        {({ y, opacity }: { y: number; opacity: number }) => (
          <Modal
            {...modalProps}
            overlayStyle={{ opacity: opacity }}
            containerStyle={{ position: 'relative', top: `${y}px` }}
          >
            <ModalHeader {...headerProps} />

            <ModalContent>
              <CustomRoleForm onSubmit={onSubmit} />
            </ModalContent>

            <ModalFooter>
              <div>
                <Button variant={VARIANTS.PRIMARY} inverse onClick={onCancel}>
                  {I18n.t('screens.admin.roles.buttons.cancel')}
                </Button>
                <Button
                  variant={VARIANTS.PRIMARY}
                  className="admin-roles-modal-save-button"
                  onClick={onSubmit}
                >
                  {I18n.t(`screens.admin.roles.buttons.${editingNewRole ? 'create' : 'save'}`)}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </ConditionTransitionMotion>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomRoleModal);
