import React from 'react';

export default class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className="loadinginprogress">
        <span className="spinnerdefault spinnerlarge" />
      </div>
    );
  }
}
