import React, { Component } from 'react';

import { GridProps } from './types';

export class Header extends Component<GridProps> {
  render() {
    const { children, className } = this.props;
    return <div className={`admin-roles-header ${className}`}>{children}</div>;
  }
}

export default Header;
