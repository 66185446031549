import cx from 'classnames';
import * as React from 'react';

import ExpanderIcon from './ExpanderIcon';

interface Props {
  children: any;
  className?: string;
  isExpanded?: boolean;
  onClick: (evt: React.SyntheticEvent<HTMLElement>) => void;
}

export class Trigger extends React.Component<Props> {
  static defaultProps = {
    className: '',
    isExpanded: false
  };

  render() {
    const { className, isExpanded, onClick } = this.props;
    const classes = cx(
      {
        expanded: isExpanded
      },
      className
    );
    return (
      <div className="admin-roles-expandable-trigger">
        <div className={classes} onClick={onClick}>
          <ExpanderIcon isExpanded={isExpanded} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Trigger;
