import forEach from 'lodash/fp/forEach';
import getOr from 'lodash/fp/getOr';
import merge from 'lodash/fp/merge';
import React from 'react';
import ReactDOM from 'react-dom';

import App, { createRolesAdminStore } from './components/App';
import type { ServerConfig } from 'common/types/windowSocrata';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    blist: Record<string, unknown>;
    serverConfig?: ServerConfig;
  }
}

window.addEventListener('load', function () {
  forEach((mountPoint) => {
    const config: ServerConfig = merge(
      { translations: getOr({}, 'translations', window.blist) },
      { ...window.socrata, ...window.serverConfig }
    ) as ServerConfig; // Casting because window.socrata or window.serverConfig WILL exist
    const store = createRolesAdminStore(config);

    ReactDOM.render(<App serverConfig={config} store={store} />, mountPoint);
  }, document.querySelectorAll('[data-react-component="RolesAdmin"]'));
});
