import PropTypes from 'prop-types';
import React, { Component } from 'react';

/** ScrollView / ScrollElement
 * Inspired by https://codedaily.io/tutorials/8/Build-a-Reusable-Scroll-List-Component-with-Animated-scrollTo-in-React
 *
 */
interface ScrollElementProps {
  children: React.ReactElement;
  name: string;
}

export class ScrollElement extends Component<ScrollElementProps> {
  _element: HTMLElement;
  static contextTypes = {
    scroll: PropTypes.object
  };

  componentDidMount() {
    this.context.scroll.register(this.props.name, this._element);
  }

  componentWillUnmount() {
    this.context.scroll.unregister(this.props.name);
  }

  render() {
    return React.cloneElement(this.props.children, {
      ref: (ref: HTMLElement) => (this._element = ref)
    });
  }
}

export default ScrollElement;
