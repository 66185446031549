import includes from 'lodash/fp/includes';
import React, { Component } from 'react';
import { spring } from 'react-motion';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import ConditionTransitionMotion from 'common/components/ConditionTransitionMotion';

import * as Actions from '../actions';
import * as Selectors from '../adminRolesSelectors';
import { EDIT_CUSTOM_ROLES, EDIT_INDIVIDUAL_CUSTOM_ROLE, SAVING } from '../appStates';

import type { State } from 'adminRoles/types';
import type { Role } from '@socrata/core-roles-api';

import './save-bar.scss';

interface OwnProps {
  style: any;
}

interface StateProps {
  dirtyRoles: Role[];
  isSaving: boolean;
  isEditMode: boolean;
  hasCustomRoles: boolean;
}

const mapStateToProps = (state: State): StateProps => {
  const appState = Selectors.getAppState(state);
  const dirtyRoles = Selectors.getDirtyRolesFromState(state);
  return {
    isEditMode: includes(appState, [EDIT_CUSTOM_ROLES, SAVING, EDIT_INDIVIDUAL_CUSTOM_ROLE]),
    isSaving: appState === SAVING,
    hasCustomRoles: Selectors.stateHasCustomRoles(state),
    dirtyRoles
  };
};

interface DispatchProps {
  editCustomRolesCancel: () => Actions.EditCustomRolesCancelAction;
  saveRoles: (roles: Role[]) => Actions.SaveRolesAction;
}

const mapDispatchToProps: DispatchProps = {
  editCustomRolesCancel: Actions.editCustomRolesCancel,
  saveRoles: Actions.saveRoles
};

type Props = OwnProps & StateProps & DispatchProps;

class SaveBar extends Component<Props> {
  renderCancelButton = () => {
    const { editCustomRolesCancel, isSaving } = this.props;

    return (
      <Button
        variant={VARIANTS.PRIMARY}
        inverse={!isSaving}
        buttonDisabledStyle={'light'}
        disabled={isSaving}
        onClick={editCustomRolesCancel}
      >
        {I18n.t('screens.admin.roles.buttons.cancel')}
      </Button>
    );
  };

  renderSaveButton = () => {
    const { dirtyRoles, isSaving, saveRoles } = this.props;

    return (
      <Button
        variant={VARIANTS.PRIMARY}
        busy={isSaving}
        className="admin-roles-save-button"
        onClick={() => saveRoles(dirtyRoles)}
        disabled={isSaving}
      >
        <div style={{ visibility: isSaving ? 'hidden' : 'visible' }}>
          {I18n.t('screens.admin.roles.buttons.save')}
        </div>
        {isSaving && (
          <div className="admin-roles-spinner-container">
            <span className="spinner-default spinner-btn-primary" />
          </div>
        )}
      </Button>
    );
  };

  render() {
    const { style } = this.props;

    return (
      <div style={style} className="admin-roles-save-bar">
        {this.renderCancelButton()}
        {this.renderSaveButton()}
      </div>
    );
  }
}

const StyledActionBar = SaveBar;

interface AnimatedActionBarProps {
  dirtyRoles: Role[];
  editCustomRolesCancel: () => Actions.EditCustomRolesCancelAction;
  hasCustomRoles: boolean;
  isEditMode: boolean;
  isSaving: boolean;
  saveRoles: (roles: Role[]) => Actions.SaveRolesAction;
  style?: { [key: string]: string | number };
}

class AnimatedActionBar extends Component<AnimatedActionBarProps> {
  render() {
    const { hasCustomRoles, isEditMode } = this.props;
    return (
      <ConditionTransitionMotion
        condition={hasCustomRoles && isEditMode}
        willEnter={() => ({ bottom: -72 })}
        willLeave={() => ({ bottom: spring(-72) })}
        style={{ bottom: spring(0) }}
      >
        {(style: { [key: string]: string }) => <StyledActionBar {...this.props} style={style} />}
      </ConditionTransitionMotion>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnimatedActionBar);
