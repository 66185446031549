import cx from 'classnames';
import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import noop from 'lodash/fp/noop';
import omit from 'lodash/fp/omit';
import React, { Component } from 'react';

import './bounded-text-input.scss';

const CHARACTER_COUNT_LEVELS = {
  DEFAULT: 'default',
  WARNING: 'warning',
  ERROR: 'error'
};

interface OwnProps {
  className?: string;
  inputRef: (el: HTMLInputElement | null) => void;
  maxCharacterCount: number;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  styles?: { [key: string]: string };
}

interface State {
  characterCount: number;
  characterCountLevel: string;
}

type Props = OwnProps & React.AllHTMLAttributes<HTMLInputElement>;

class BoundedTextInput extends Component<Props, State> {
  static defaultProps = {
    inputRef: noop,
    onChange: noop,
    value: ''
  };

  state = {
    characterCount: 0,
    characterCountLevel: CHARACTER_COUNT_LEVELS.DEFAULT
  };

  inputRef: HTMLInputElement | null;

  componentDidMount() {
    this.updateCharacterCount();
  }

  updateCharacterCount = () => {
    const { maxCharacterCount } = this.props;
    const characterCount = this.inputRef ? this.inputRef.value.length : 0;
    const characterCountLevel = cond<any, string>([
      [(max) => characterCount > max, constant(CHARACTER_COUNT_LEVELS.ERROR)],
      [(max) => characterCount > max - 10, constant(CHARACTER_COUNT_LEVELS.WARNING)],
      [() => true, constant(CHARACTER_COUNT_LEVELS.DEFAULT)]
    ])(maxCharacterCount);
    this.setState({ characterCount, characterCountLevel });
  };

  onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // EN-50326
    // Solution based on https://stackoverflow.com/questions/35535688/stop-cursor-jumping-when-formatting-number-in-react/49648061#49648061
    const el = ev.target;
    const pointer = el.selectionStart || 0;
    window.requestAnimationFrame(() => {
      el.setSelectionRange(pointer, pointer);
    });

    this.updateCharacterCount();
    this.props.onChange(ev);
  };

  render() {
    const { maxCharacterCount, className, inputRef, ...inputProps } = omit(
      ['onChange', 'styles'],
      this.props
    );
    const { characterCount, characterCountLevel } = this.state;
    const classes = cx(
      {
        [`text-input-${characterCountLevel}`]: characterCountLevel
      },
      'text-input',
      className
    );
    return (
      <div className="admin-roles-bounded-text-input">
        <input
          className={classes}
          onChange={this.onChange}
          ref={(ref) => {
            this.inputRef = ref;
            inputRef(ref);
          }}
          type="text"
          {...inputProps}
        />
        <small className="admin-roles-character-count">
          {characterCount}/{maxCharacterCount}
        </small>
      </div>
    );
  }
}

export default BoundedTextInput;
