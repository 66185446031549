import { applyMiddleware, compose, createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import React from 'react';
import createSagaMiddleware from 'redux-saga';

import { getInitialState } from '../adminRolesSelectors';

import RolesAdmin from './RolesAdmin';
import reducer from '../reducers/RolesAdminReducer';
import sagas from '../sagas';
import { State } from 'adminRoles/types';
import { ServerConfig } from 'common/types/windowSocrata';

const sagaMiddleware = createSagaMiddleware();
const devToolsConfig = {
  actionsDenylist: ['HOVER_ROW', 'UNHOVER_ROW', 'CHANGE_NEW_ROLE_NAME'],
  name: 'Roles & Permissions Admin'
};

// TODO: standardize redux/react app bootstrapping - EN-22364
export const createRolesAdminStore = (serverConfig: ServerConfig) => {
  const initialState: State = getInitialState(serverConfig);
  const middleware = [sagaMiddleware];

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)) ||
    compose;
  const enhancer = composeEnhancers(applyMiddleware(...middleware));
  const store = createStore(reducer(), initialState, enhancer);
  sagaMiddleware.run(sagas);
  return store;
};
interface AppArgs {
  store: Store<State>;
  serverConfig: ServerConfig;
}

const App = ({ store }: AppArgs) => (
  <Provider store={store}>
    <RolesAdmin />
  </Provider>
);

export default App;
