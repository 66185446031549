import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import ToastNotification from 'common/components/ToastNotification';

import LoadingSpinner from './LoadingSpinner';
import * as Actions from '../actions';
import * as Selectors from '../adminRolesSelectors';
import { LOAD_DATA_FAILURE, LOADING } from '../appStates';
import EditBar from './EditBar';
import SaveBar from './SaveBar';
import RolesGrid from './grid/RolesGrid';
import EditCustomRoleModal from './modal/EditCustomRoleModal';
import AppError from './util/AppError';

import get from 'lodash/fp/get';
import { State } from 'adminRoles/types';

import './roles-admin.scss';

interface StateProps {
  hasLoadDataFailure: boolean;
  isLoading: boolean;
  notification: {
    content: string;
    [key: string]: string;
  };
}

const mapStateToProps = (state: State): StateProps => {
  const appState = Selectors.getAppState(state);
  const notificationObj = Selectors.getNotificationFromState(state);
  const notification = {
    ...notificationObj,
    content: I18n.t(get('content', notificationObj), notificationObj)
  };
  return {
    hasLoadDataFailure: appState === LOAD_DATA_FAILURE,
    isLoading: appState === LOADING,
    notification
  };
};

interface DispatchProps {
  loadData: () => Actions.LoadDataAction;
}

const mapDispatchToProps: DispatchProps = {
  loadData: Actions.loadData
};

type Props = StateProps & DispatchProps;

export class RolesAdmin extends Component<Props> {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const { hasLoadDataFailure, isLoading, notification } = this.props;
    return (
      <div>
        <ToastNotification {...notification}>
          <span dangerouslySetInnerHTML={{ __html: notification.content }} />
        </ToastNotification>
        {hasLoadDataFailure ? (
          <AppError />
        ) : (
          <div>
            <EditCustomRoleModal />
            <EditBar />
            <div className="admin-roles-content">
              <div className="admin-roles-description">
                <h2>{I18n.t('screens.admin.roles.index_page.description.title')}</h2>
                <p>{I18n.t('screens.admin.roles.index_page.description.content')}</p>
              </div>
              {isLoading ? (
                <div className="admin-roles-loading-spinner">
                  <LoadingSpinner />
                </div>
              ) : (
                <div>
                  <RolesGrid />
                </div>
              )}
            </div>
            <SaveBar />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesAdmin);
