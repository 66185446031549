import getOr from 'lodash/fp/getOr';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from '../../actions';
import * as selectors from '../../adminRolesSelectors';
import Expandable from '../util/Expandable';
import { Cell } from '../util/Grid';
import Hoverable from '../util/Hoverable';
import TristateCheckbox from '../util/TristateCheckbox';
import TristateIndicator from 'common/components/TristateIndicator';

import { Dispatch } from '../../actions';
import type { SortedRight, RightsCategory, Role } from '@socrata/core-roles-api';
import { DomainRoleMask, MaskedRights } from '@socrata/core-domain-roles-mask-api';
import { ForgeTooltip } from '@tylertech/forge-react';
import I18n from 'common/i18n';

// We used to pull this from ../variables but it no longer works.
const cellHeight = 60;

const approvalsCategory = 'review_and_approve_assets';

interface RoleCheckbox {
  disabled: boolean;
  checked: boolean;
}

interface OwnProps {
  role: Role;
  rightCategory: RightsCategory;
  domainRoleMask: DomainRoleMask[];
  roleName: string;
  editingColumn: boolean;
  isDefault: boolean;
  editMode: boolean;
}

interface DispatchProps {
  toggleRoleRightCategoryValue: (
    evt: React.SyntheticEvent<HTMLElement>
  ) => Actions.ToggleRoleRightCategoryValueAction;
  toggleRoleRightValue: (right: SortedRight) => Actions.ToggleRoleRightValueAction;
}

const mapDispatchToProps = (dispatch: Dispatch, { role, rightCategory }: OwnProps): DispatchProps =>
  bindActionCreators(
    {
      toggleRoleRightCategoryValue: () => Actions.toggleRoleRightCategoryValue(role, rightCategory),
      toggleRoleRightValue: (right) => Actions.toggleRoleRightValue(role, right)
    },
    dispatch
  );

type Props = OwnProps & DispatchProps;

class RoleRightCategory extends Component<Props> {
  render() {
    const {
      editingColumn,
      isDefault,
      role,
      rightCategory,
      roleName,
      domainRoleMask,
      toggleRoleRightCategoryValue
    } = this.props;

    return (
      <Expandable
        className="expandable-container"
        itemContainerClassName="expandable-item-container"
        key={`${roleName}_${selectors.getTranslationKeyFromRightsCategory(rightCategory)}`}
        itemHeight={cellHeight}
        isExpanded={selectors.getExpandedStateFromRightsCategory(rightCategory)}
      >
        <Hoverable name={getOr('', 'translationKey', rightCategory)}>
          <Cell className="role-cell">
            {isDefault ? (
              <TristateIndicator checkedState={selectors.rightCategoryStateForRole(role, rightCategory)} />
            ) : this.isApprovalsCategoryInEditMode(rightCategory.translationKey) ? (
              <div></div>
            ) : editingColumn ? (
              <TristateCheckbox
                id={`${roleName}_${selectors.getTranslationKeyFromRightsCategory(rightCategory)}`}
                checkedState={selectors.rightCategoryStateForRole(role, rightCategory)}
                onChange={toggleRoleRightCategoryValue}
              />
            ) : (
              <TristateIndicator checkedState={selectors.rightCategoryStateForRole(role, rightCategory)} />
            )}
          </Cell>
        </Hoverable>
        {this.renderEmptyRowIfApprovalsCategory(rightCategory.translationKey)}
        {selectors.getRightsFromRightsCategory(rightCategory).map((right) => (
          <Hoverable name={getOr('', 'name', right)} key={`${roleName}_${selectors.getNameFromRight(right)}`}>
            <Cell className="role-cell">{this.renderCells(rightCategory, role, right, domainRoleMask)}</Cell>
          </Hoverable>
        ))}
      </Expandable>
    );
  }

  // Determines which cell option to use:
  // indicator - if role is default or custom role has specific rights;
  // empty cell - if category is 'review_and_approve_assets' and it's in editing mode;
  // checkbox: if it's editing column, amd if it has any masked rights;
  renderCells = (
    rightCategoryName: RightsCategory,
    role: Role,
    right: SortedRight,
    domainRoleMask: DomainRoleMask[]
  ): JSX.Element => {
    if (this.props.isDefault) {
      return <TristateIndicator checkedState={selectors.roleHasRight(role, right)} />;
    } else if (this.isApprovalsCategoryInEditMode(rightCategoryName.translationKey)) {
      return <div></div>;
    } else if (this.props.editingColumn) {
      if (this.anyMaskedRights(domainRoleMask, right.name)) {
        return (
          <div>
            {this.generateTristateCheckbox(right, role, domainRoleMask)}
            <ForgeTooltip delay={100} id="required_right_flyout">
              {I18n.t('screens.admin.roles.alerts.all_users_permission')}
            </ForgeTooltip>
          </div>
        );
      } else if (rightCategoryName.translationKey === approvalsCategory) {
        return <div></div>;
      }
      return <div>{this.generateTristateCheckbox(right, role, domainRoleMask)}</div>;
    } else return <TristateIndicator checkedState={selectors.roleHasRight(role, right)} />;
  };

  // Create empty cells specifically for review_and_approve_assets category, so they form an empty line
  // Nested <div> is used to reset the background to white and then apply blue color with transparency
  renderEmptyRowIfApprovalsCategory = (rightCategoryName: string): JSX.Element | undefined => {
    if (this.isApprovalsCategoryInEditMode(rightCategoryName)) {
      return (
        <div className="inline-message">
          <div className="inline-message-2" />
        </div>
      );
    }
  };

  // Determines whether it is Approvals Category and in Editing Mode
  isApprovalsCategoryInEditMode = (rightCategoryName: string): boolean => {
    return rightCategoryName === approvalsCategory && this.props.editMode;
  };

  // Extract masks for specific role from main list of masks
  getDomainRoleMaskForRole(role: Role, domainRoleMask: DomainRoleMask[]): MaskedRights | undefined {
    return domainRoleMask.find((drm) => drm.role.name == role.name)?.maskedRights;
  }

  // Determine if the specified right is masked
  anyMaskedRights(domainRoleMask: DomainRoleMask[], rightName: string): boolean {
    return domainRoleMask.some(
      (drm) => drm.maskedRights.added?.includes(rightName) || drm.maskedRights.removed?.includes(rightName)
    );
  }

  // This determines whether the checkbox in edit mode should be checked, unchecked, disabled, or enabled
  // and generates the JSX Element accordingly
  generateTristateCheckbox(right: SortedRight, role: Role, domainRoleMask: DomainRoleMask[]): JSX.Element {
    const mask = this.getDomainRoleMaskForRole(role, domainRoleMask); // if mask is not undefined, then this is an existing role
    const rightIsMasked = this.anyMaskedRights(domainRoleMask, right.name);
    let checkedState = false;
    let disabled = false;

    if (rightIsMasked) {
      const checkboxState: RoleCheckbox = this.getCheckboxValuesForRight(domainRoleMask, right.name);
      checkedState = checkboxState.checked;
      disabled = checkboxState.disabled;
    } else if (mask && mask.added && mask.added.indexOf(right.name) != -1) {
      checkedState = true;
      disabled = true;
    } else if (mask && mask.removed && mask.removed.indexOf(right.name) != -1) {
      checkedState = false;
      disabled = true;
    } else if (!rightIsMasked) {
      // this indicates we're creating an unmasked Right checkbox on a new Role
      checkedState = selectors.roleHasRight(role, right);
    }

    return (
      <div>
        <TristateCheckbox
          id={`${role.name}_${selectors.getNameFromRight(right)}`}
          checkedState={checkedState}
          disabled={disabled}
          onChange={() => this.props.toggleRoleRightValue(right)}
        />
      </div>
    );
  }

  // Determine if the specified right is masked and return values to generate tristate checkbox
  getCheckboxValuesForRight(domainRoleMask: DomainRoleMask[], rightName: string): RoleCheckbox {
    if (domainRoleMask.find((drm) => drm.maskedRights.added?.includes(rightName))) {
      return {
        checked: true,
        disabled: true
      };
    }
    if (domainRoleMask.find((drm) => drm.maskedRights.removed?.includes(rightName))) {
      return {
        checked: false,
        disabled: true
      };
    }
    return {
      checked: false,
      disabled: false
    };
  }
}

export default connect(null, mapDispatchToProps)(RoleRightCategory);
