import cx from 'classnames';
import isString from 'lodash/fp/isString';
import * as React from 'react';
import { Motion, spring } from 'react-motion';

export { default as Trigger } from './Trigger';

import './expandable.scss';

interface Props {
  children: any;
  className?: string;
  isExpanded: boolean;
  itemHeight: number;
  itemContainerClassName?: string;
}

export class Expandable extends React.Component<Props> {
  static defaultProps = {
    isExpanded: false,
    itemContainerClassName: ''
  };

  render() {
    const { itemHeight, isExpanded = false } = this.props;
    const className = cx(this.props.itemContainerClassName || 'admin-roles-expandable');
    const [firstChild, ...children] = React.Children.toArray(this.props.children) as React.ReactElement[]; // TODO: not sure about this cast
    const numItems = children.length;
    const FirstItem = firstChild
      ? isString((firstChild as React.ReactElement & { type: any }).type)
        ? firstChild
        : React.cloneElement(firstChild, { isExpanded })
      : null;
    return (
      <div className={this.props.className}>
        {FirstItem}
        <Motion style={{ height: spring(isExpanded ? numItems * itemHeight : 0) }}>
          {(style: { [key: string]: number }) => (
            <div className={className} style={style}>
              {children}
            </div>
          )}
        </Motion>
      </div>
    );
  }
}

export default Expandable;
