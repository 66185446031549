import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';

import * as selectors from '../../adminRolesSelectors';
import { Cell, Column, Header } from '../util/Grid';
import RightCategory from './RightCategory';

import type { State } from '../../types';
import type { RightsCategory } from '@socrata/core-roles-api';

interface StateProps {
  rightCategories: RightsCategory[];
  isEditCustomRolesAppState: boolean;
  isEditIndividualRoleAppState: boolean;
}

const mapStateToProps = (state: State): StateProps => ({
  rightCategories: selectors.getRightCategoriesFromState(state),
  isEditCustomRolesAppState: selectors.isEditCustomRolesAppState(state),
  isEditIndividualRoleAppState: selectors.isEditIndividualRoleAppState(state)
});

type Props = StateProps;

class RightsColumn extends Component<Props> {
  render() {
    const { rightCategories, isEditCustomRolesAppState, isEditIndividualRoleAppState } = this.props;

    const editMode = isEditCustomRolesAppState || isEditIndividualRoleAppState;

    return (
      <Column className="rights-column">
        <Header className="rights-header-cell">
          <h4>{I18n.t('screens.admin.roles.index_page.grid.role')}</h4>
        </Header>
        {rightCategories.map((rightCategory) => (
          <RightCategory
            key={selectors.getTranslationKeyFromRightsCategory(rightCategory)}
            rightCategory={rightCategory}
            editMode={editMode}
          />
        ))}
        <Cell className="right-footer-cell">{I18n.t('screens.admin.roles.index_page.grid.currently')}:</Cell>
      </Column>
    );
  }
}

export default connect(mapStateToProps)(RightsColumn);
