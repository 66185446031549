import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import eq from 'lodash/fp/eq';
import stubTrue from 'lodash/fp/stubTrue';
import React, { Component } from 'react';

import { IconName, SocrataIcon } from 'common/components/SocrataIcon';

import './tristate-checkbox.scss';

export type CheckedState = boolean | 'partial';

interface Props {
  checkedState: CheckedState;
  disabled?: boolean;
  id: string;
  onChange: (evt: React.SyntheticEvent<HTMLElement>) => void;
  styles?: { [key: string]: string };
}

/*
 * A three-state checkbox.
 * This component is superseded by a11y-compliant common/components/ThreeStateCheckbox
 * TODO: Make this a11y-compliant by supporting the "indeterminate" property on <input>.
 * There is an example in ThreeStateCheckbox.
 */
class TristateCheckbox extends Component<Props> {
  render() {
    const { checkedState, disabled = false, id, onChange } = this.props;
    const isChecked = checkedState !== false;
    const icon = cond<CheckedState, JSX.Element | string>([
      [eq(true), constant(<SocrataIcon name={IconName.Check2} />)],
      [eq(false), constant('')],
      [stubTrue, constant(<SocrataIcon name={IconName.IndeterminateStateCheckbox} />)]
    ])(checkedState);

    return (
      <form className="admin-roles-tristate-checkbox">
        <div className="checkbox">
          <input id={id} type="checkbox" checked={isChecked} disabled={disabled} readOnly />
          <label htmlFor={id} onClick={(ev) => !disabled && onChange(ev)}>
            <span className="fake-checkbox">{icon}</span>
          </label>
        </div>
      </form>
    );
  }
}

export default TristateCheckbox;
