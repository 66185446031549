import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

import * as Selectors from '../../adminRolesSelectors';
import type { State } from 'adminRoles/types';
import type { Role } from '@socrata/core-roles-api';

import './template-dropdown.scss';

const roleTypeAsTranslationString = (role: Role) => (Selectors.roleIsCustom(role) ? 'custom' : 'default');

interface StateProps {
  templates: DropdownOption[];
}

const mapStateToProps = (state: State): StateProps => ({
  templates: Selectors.getRolesFromState(state).map((role: Role) => ({
    title: Selectors.roleIsCustom(role)
      ? Selectors.getRoleNameFromRole(role)
      : I18n.t(Selectors.getRoleNameTranslationKeyPathFromRole(role)),
    value: Selectors.getIdFromRole(role),
    group: I18n.t(
      `screens.admin.roles.index_page.custom_role_modal.form.template.${roleTypeAsTranslationString(role)}`
    )
  }))
});

interface DropdownOption {
  title: string;
  value: any;
  group?: string;
}

interface OwnProps {
  onChange: (value: string) => void;
  value?: string | number;
}

type Props = StateProps & OwnProps;

class TemplateDropdown extends Component<Props> {
  render() {
    const { onChange, templates, value } = this.props;
    const dropdownProps = {
      value,
      onSelection: (option: DropdownOption) => onChange(option.value),
      options: [
        {
          title: I18n.t('screens.admin.roles.index_page.custom_role_modal.form.template.none_selected'),
          value: null
        }
      ].concat(templates),
      placeholder: I18n.t('screens.admin.roles.index_page.custom_role_modal.form.template.none_selected')
    };

    return (
      <div className="admin-roles-modal-template-dropdown">
        <Dropdown {...dropdownProps} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(TemplateDropdown);
