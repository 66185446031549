import { ApiDataResponse } from 'adminRoles/types';
import _ from 'lodash';

export const denyList: string[] = [];

export function filterRights(data: ApiDataResponse): ApiDataResponse {
  data.rightCategories.forEach(function (rightCategory) {
    const filteredRights = _.filter(rightCategory.rights, function (right) {
      return denyList.indexOf(right.name) === -1;
    });
    rightCategory.rights = filteredRights;
  });

  return data;
}
