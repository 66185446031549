import { Dispatch as ReduxDispatch } from 'redux';
import { action } from 'typesafe-actions';

import { ToastType as NotificationTypes } from 'common/components/ToastNotification';

import type { ApiSuccess } from './types';
import type { Role, RightsCategory, SortedRight } from '@socrata/core-roles-api';
import { DomainRoleMask } from '@socrata/core-domain-roles-mask-api';

export const EDIT_CUSTOM_ROLES_START = 'EDIT_CUSTOM_ROLES_START';
export const editCustomRolesStart = () => action(EDIT_CUSTOM_ROLES_START);
export type EditCustomRolesStartAction = ReturnType<typeof editCustomRolesStart>;

export const EDIT_CUSTOM_ROLES_END = 'EDIT_CUSTOM_ROLES_END';
export const editCustomRolesEnd = () => action(EDIT_CUSTOM_ROLES_END);
export type EditCustomRolesEndAction = ReturnType<typeof editCustomRolesEnd>;

export const EDIT_CUSTOM_ROLES_CANCEL = 'EDIT_CUSTOM_ROLES_CANCEL';
export const editCustomRolesCancel = () => action(EDIT_CUSTOM_ROLES_CANCEL);
export type EditCustomRolesCancelAction = ReturnType<typeof editCustomRolesCancel>;

export const NEW_CUSTOM_ROLE = 'NEW_CUSTOM_ROLE';
export const newCustomRole = () => action(NEW_CUSTOM_ROLE);
export type NewCustomRoleAction = ReturnType<typeof newCustomRole>;

export const TOGGLE_EXPANDED = 'TOGGLE_EXPANDED';
export const toggleExpanded = (rightCategory: RightsCategory) =>
  action(TOGGLE_EXPANDED, {
    rightCategory
  });
export type ToggleExpandedAction = ReturnType<typeof toggleExpanded>;

export const SAVE_ROLES = 'SAVE_ROLES';
export const saveRoles = (roles: Role[]) => action(SAVE_ROLES, { roles });
export type SaveRolesAction = ReturnType<typeof saveRoles>;

export const SAVE_ROLES_START = 'SAVE_ROLES_START';
export const saveRolesStart = () => action(SAVE_ROLES_START);
export type SaveRolesStartAction = ReturnType<typeof saveRolesStart>;

export const SAVE_ROLES_SUCCESS = 'SAVE_ROLES_SUCCESS';
export const saveRolesSuccess = (successes: ApiSuccess[]) => action(SAVE_ROLES_SUCCESS, { successes });
export type SaveRolesSuccessAction = ReturnType<typeof saveRolesSuccess>;

export const SAVE_ROLES_FAILURE = 'SAVE_ROLES_FAILURE';
export const saveRolesFailure = () => action(SAVE_ROLES_FAILURE, { error: true });
export type SaveRolesFailureAction = ReturnType<typeof saveRolesFailure>;

export const CREATE_NEW_ROLE_START = 'CREATE_NEW_ROLE_START';
export const createNewRoleStart = () => action(CREATE_NEW_ROLE_START);
export type CreateNewRoleStartAction = ReturnType<typeof createNewRoleStart>;

export const EDIT_CUSTOM_ROLE_MODAL_SUBMIT = 'EDIT_CUSTOM_ROLE_MODAL_SUBMIT';
export const editCustomRoleModalSubmit = () => action(EDIT_CUSTOM_ROLE_MODAL_SUBMIT);
export type EditCustomRoleModalSubmitAction = ReturnType<typeof editCustomRoleModalSubmit>;

export const EDIT_CUSTOM_ROLE_MODAL_CANCEL = 'EDIT_CUSTOM_ROLE_MODAL_CANCEL';
export const editCustomRoleModalCancel = () => action(EDIT_CUSTOM_ROLE_MODAL_CANCEL);
export type EditCustomRoleModalCancelAction = ReturnType<typeof editCustomRoleModalCancel>;

export const CHANGE_NEW_ROLE_NAME = 'CHANGE_NEW_ROLE_NAME';
export const changeNewRoleName = (name: string) => action(CHANGE_NEW_ROLE_NAME, { name });
export type ChangeNewRoleNameAction = ReturnType<typeof changeNewRoleName>;

export const CHANGE_NEW_ROLE_TEMPLATE = 'CHANGE_NEW_ROLE_TEMPLATE';
export const changeNewRoleTemplate = (value: string) => action(CHANGE_NEW_ROLE_TEMPLATE, { value });
export type ChangeNewRoleTemplateAction = ReturnType<typeof changeNewRoleTemplate>;

export const TOGGLE_ROLE_RIGHT_VALUE = 'TOGGLE_ROLE_RIGHT_VALUE';
export const toggleRoleRightValue = (role: Role, right: SortedRight) =>
  action(TOGGLE_ROLE_RIGHT_VALUE, { role, right });
export type ToggleRoleRightValueAction = ReturnType<typeof toggleRoleRightValue>;

export const TOGGLE_ROLE_RIGHT_CATEGORY_VALUE = 'TOGGLE_ROLE_RIGHT_CATEGORY_VALUE';
export const toggleRoleRightCategoryValue = (role: Role, rightCategory: RightsCategory) =>
  action(TOGGLE_ROLE_RIGHT_CATEGORY_VALUE, { role, rightCategory });
export type ToggleRoleRightCategoryValueAction = ReturnType<typeof toggleRoleRightCategoryValue>;

export const DELETE_ROLE = 'DELETE_ROLE';
export const deleteRole = (role: Role) => action(DELETE_ROLE, { role });
export type DeleteRoleAction = ReturnType<typeof deleteRole>;

export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const deleteRoleStart = (role: Role) => action(DELETE_ROLE_START, { role });
export type DeleteRoleStartAction = ReturnType<typeof deleteRoleStart>;

export const DELETE_ROLE_END = 'DELETE_ROLE_END';
export const deleteRoleEnd = () => action(DELETE_ROLE_END);
export type DeleteRoleEndAction = ReturnType<typeof deleteRoleEnd>;

export const DELETE_ROLE_CANCEL = 'DELETE_ROLE_CANCEL';
export const deleteRoleCancel = () => action(DELETE_ROLE_CANCEL);
export type DeleteRoleCancelAction = ReturnType<typeof deleteRoleCancel>;

export const EDIT_ROLE_START = 'EDIT_ROLE_START';
export const editRoleStart = (role: Role) => action(EDIT_ROLE_START, { role });
export type EditRoleStartAction = ReturnType<typeof editRoleStart>;

export const RENAME_ROLE = 'RENAME_ROLE';
export const renameRole = (role: Role) => action(RENAME_ROLE, { role });
export type RenameRoleAction = ReturnType<typeof renameRole>;

export const LOAD_DATA_START = 'LOAD_DATA_START';
export const loadDataStart = () => action(LOAD_DATA_START);
export type LoadDataStartAction = ReturnType<typeof loadDataStart>;

export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS';
export const loadDataSuccess = (data: {
  rightCategories: RightsCategory[];
  roles: Role[];
  domainRoleMask: DomainRoleMask[];
}) => action(LOAD_DATA_SUCCESS, { data });
export type LoadDataSuccessAction = ReturnType<typeof loadDataSuccess>;

export const LOAD_DATA_FAILURE = 'LOAD_DATA_FAILURE';
export const loadDataFailure = (error: Error) => action(LOAD_DATA_FAILURE, { error }, { error: true });
export type LoadDataFailureAction = ReturnType<typeof loadDataFailure>;

export const LOAD_DATA = 'LOAD_DATA';
export const loadData = () => action(LOAD_DATA);
export type LoadDataAction = ReturnType<typeof loadData>;

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const showNotification = (type: NotificationTypes, content: string, options: any = {}) =>
  action(SHOW_NOTIFICATION, { type, content, ...options });
export type ShowNotificationAction = ReturnType<typeof showNotification>;

export const showNotificationError = (
  content: string,
  options: { [key: string]: any } = {}
): ShowNotificationAction => showNotification(NotificationTypes.ERROR, content, options);
export const showNotificationSuccess = (
  content: string,
  options: { [key: string]: any } = {}
): ShowNotificationAction => showNotification(NotificationTypes.SUCCESS, content, options);

export const SHOW_NOTIFICATION_END = 'SHOW_NOTIFICATION_END';
export const showNotificationEnd = () => action(SHOW_NOTIFICATION_END);
export type ShowNotificationEndAction = ReturnType<typeof showNotificationEnd>;

export const HOVER_ROW = 'HOVER_ROW';
export const hoverRow = (name: string) => action(HOVER_ROW, { name });
export type HoverRowAction = ReturnType<typeof hoverRow>;

export const UNHOVER_ROW = 'UNHOVER_ROW';
export const unhoverRow = (name: string) => action(UNHOVER_ROW, { name });
export type UnhoverRowAction = ReturnType<typeof unhoverRow>;

export type Action =
  | EditCustomRolesStartAction
  | EditCustomRolesEndAction
  | EditCustomRolesCancelAction
  | NewCustomRoleAction
  | ToggleExpandedAction
  | SaveRolesAction
  | SaveRolesStartAction
  | SaveRolesSuccessAction
  | SaveRolesFailureAction
  | CreateNewRoleStartAction
  | EditCustomRoleModalSubmitAction
  | EditCustomRoleModalCancelAction
  | ChangeNewRoleNameAction
  | ChangeNewRoleTemplateAction
  | ToggleRoleRightValueAction
  | ToggleRoleRightCategoryValueAction
  | DeleteRoleAction
  | DeleteRoleStartAction
  | DeleteRoleEndAction
  | DeleteRoleCancelAction
  | EditRoleStartAction
  | RenameRoleAction
  | LoadDataStartAction
  | LoadDataSuccessAction
  | LoadDataFailureAction
  | LoadDataAction
  | ShowNotificationAction
  | ShowNotificationEndAction
  | HoverRowAction
  | UnhoverRowAction;
export type Dispatch = ReduxDispatch<Action>;
