import React, { Component } from 'react';

import './app-error.scss';

class AppError extends Component {
  render() {
    return (
      <div className="admin-roles-app-error">
        <div className="alert error">
          An error has occurred while loading data for this page. Please try reloading the page, or contact
          customer support if the problem persists.
        </div>
      </div>
    );
  }
}

export default AppError;
